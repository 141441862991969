import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { mapEdgesToNodes } from "../../lib/helpers";
import SEO from "../../components/seo";
import Container from "../../components/container";
import Elevator from "../../components/elevator";
import PtPackage from "../../components/ptPackage";
import FormSection from "../../components/formSection";
import Layout from "../../components/layout";
import TestimonialShowcase from "../../components/testimonialShowcase";

import "../../styles/layout.scss";

export const query = graphql`
  query runningCoachingPageQuery {
    runningCoaching: allSanityPage(filter: { name: { eq: "Løpecoaching" } }) {
      edges {
        node {
          name
          _rawBody
          _rawExtraBody
          _id
          image {
            alt
            asset {
              _id
              url
            }
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
          }
        }
      }
    }
    ptPackages: allSanityRunPtPackage {
      edges {
        node {
          _id
          _rawDescription
          _rawIncludes
          mainImage {
            alt
            asset {
              _id
              url
            }
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
          }
          title
          price
        }
      }
    }
    formImages: allSanitySlider {
      edges {
        node {
          image {
            alt
            asset {
              _id
              url
            }
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
          }
        }
      }
    }
  }
`;

const RunningCoaching = () => {
  const data = useStaticQuery(query);
  const pageEdge = mapEdgesToNodes(data.runningCoaching);
  const elevatorContent = pageEdge[0] || [];

  const packages = mapEdgesToNodes(data.ptPackages);

  const formImages = mapEdgesToNodes(data.formImages);

  const runningImage = formImages.filter(
    (image) =>
      image.image.asset._id === "image-f8e4369b3d7c2fc5b2d1e00fdf93c35a6cb443fa-6368x6369-jpg"
  );

  return (
    <Layout>
      <SEO title="Løpe Coaching" />
      <div className="elevator__background"></div>
      <Container>
        <div className="running-coaching">
          <Elevator
            title={elevatorContent.name}
            image={elevatorContent.image}
            info={elevatorContent._rawBody}
            extraInfo={elevatorContent._rawExtraBody}
          />
          <h3 className="running-coaching__packages">PT pakker</h3>
          <div className="running-coaching__wrapper">
            {packages &&
              [...packages]
                .sort((a, b) => {
                  const aContains20 = a.title.includes("20");
                  const bContains20 = b.title.includes("20");

                  if (aContains20 && !bContains20) {
                    return 1; // 'a' should be after 'b'
                  }
                  if (!aContains20 && bContains20) {
                    return -1; // 'a' should be before 'b'
                  }
                  return 0; // 'a' and 'b' are equal
                })
                .map((program) => <PtPackage program={program} />)}
          </div>
        </div>
      </Container>
      <FormSection packages={packages} image={runningImage} formType="running" />
      <TestimonialShowcase />
    </Layout>
  );
};

export default RunningCoaching;
